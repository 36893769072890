import stateContext from './StateContext';
import { useState } from 'react';

const GlobalState = (props) => {
    const [socket, setSocket] = useState(null);
    const [selectedConversation, setSelectedConversation] = useState('dd069944-ab93-11ee-b373-56e77d115542');
    const [openSidebar, setOpenSidebar] = useState(false);
    
    return (
        <stateContext.Provider value={{
            socket, setSocket,
            selectedConversation, setSelectedConversation,
            openSidebar, setOpenSidebar
        }}>
            {props.children}
        </stateContext.Provider>
    )
}

export default GlobalState;