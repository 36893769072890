import React, { useState, useEffect, useRef, useContext } from "react";
import "../../css/form.css";
import "./moderatorLogin.css";
import { Button } from "antd";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from 'react-router-dom';
import stateContext from "../../context/stateGlobal/StateContext";
import { logger } from "../utils/Logger";

const cookies = new Cookies();
let publicUrl = process.env.PUBLIC_URL;

function ModeratorLogin() {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
	const navigate = useNavigate();
	const [redirectToRegistration, setRedirectToRegistration] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [moderatorToken, setModeratorToken] = useState("");
	const [checkModeratorToken, setCheckModeratorToken] = useState(false);
	const [loading, setLoading] = useState(false);
	const inputRef = useRef(null);
	const [linkCopied, setLinkCopied] = useState(false);
	const [emailValid, setEmailValid] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [btn2Loading, setBtn2Loading] = useState(false);
	const [btn3Loading, setBtn3Loading] = useState(false);
	const [showCreateConversationIdBtn, setShowCreateConversationIdBtn] = useState(false);
	const [createConversationId, setCreateConversationId] = useState("");
	const [otpMessage, setOtpMessage] = useState("");
	let globalState = useContext(stateContext);
	
	// const [description, setDescription] = useState("");

	const [otp, setOtp] = useState(false);

	const [loginData, setLoginData] = useState({
		email: "",
		otp: "",
	});


	useEffect(() => {
		let moderator_Token = cookies.get("moderatorToken", { path: "/" });
		if (moderator_Token) {
			navigate('/conversations')
			// setShowCreateConversationIdBtn(true);
			setModeratorToken(moderator_Token);
		} else {
			setShowCreateConversationIdBtn(false);
			setModeratorToken("");
		}
	}, [checkModeratorToken]);

	// useEffect(() => {
	// 	if (moderator_Token) {
	// 		setShowCreateConversationIdBtn(true)
	// 		setModeratorToken(moderator_Token)
	// 	}
	// }, [moderator_Token]);

	const handleRegistration = () => {
		setRedirectToRegistration(true);
		setLoginData({
			email: "",
			otp: "",
		});
		setOtp(false);
	};

	const handleDataChange = (event) => {
		const { name, value } = event.target;
		if (name === "email") {
			if (emailRegex.test(value) === true) {
				setEmailValid(true);
				setErrorMessage("");
				setLoginData((prevLoginData) => ({
					...prevLoginData,
					[name]: value,
				}));
			} else {
				setLoginData((prevLoginData) => ({
					...prevLoginData,
					[name]: value,
				}));
				setEmailValid(false);
				setErrorMessage("Invalid email format.");
			}
		} else {
			setLoginData((prevLoginData) => ({
				...prevLoginData,
				[name]: value,
			}));
		}
	};

	// const handleDescription = (event) => {
	// 	const descriptionValue = event.target.value;
	// 	setDescription(descriptionValue)

	// }

	const handleLogout = (event) => {
		setErrorMessage("");
		setBtn3Loading(true);
		cookies.remove("moderatorToken", { path: "/" });
		setCheckModeratorToken(true);
		setTimeout(() => {
			setBtn3Loading(false);
		}, 2000);
		setLoginData({
			email: "",
			otp: "",
		});
		setOtp(false);
	};

	const copyLink = (event) => {
		// Assuming createConversationId is a string
		const copyText = createConversationId;

		// Create a temporary textarea element
		const textarea = document.createElement("textarea");
		textarea.value = copyText;

		document.body.appendChild(textarea);

		textarea.select();

		// Execute the copy command
		document.execCommand("copy");

		// Remove the textarea from the document
		document.body.removeChild(textarea);

		setLinkCopied(true);

		setTimeout(() => {
			setLinkCopied(false);
		}, 3000);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (
			loginData.otp === "" &&
			loginData.email !== "" &&
			emailRegex.test(loginData.email) === true
		) {
			setBtnLoading(true);
			try {
				let data = {
					email: loginData.email,
				};
				var res = await axios.post(
					`${process.env.REACT_APP_API_BASE_URL}/login`,
					data,
					{}
				);
				if (res.data.status_code === 200) {
					setBtnLoading(false);
					setOtp(true);
					setErrorMessage("");
					setOtpMessage(res.data.message);
					setTimeout(() => {
						setOtpMessage("");
					}, 3000);
				}
			} catch (err) {
				logger.error(err);
				setErrorMessage(err.response.data.message);
				setBtnLoading(false);
			}
		} else if (
			loginData.otp !== "" &&
			loginData.email !== "" &&
			emailRegex.test(loginData.email) === true
		) {
			setBtn2Loading(true);
			try {
				let data2 = {
					email: loginData.email,
					otp: loginData.otp,
				};
				var result = await axios.post(
					`${process.env.REACT_APP_API_BASE_URL}/verify-otp`,
					data2,
					{}
				);
				if (result.data.status_code === 200) {
					navigate('/conversations')
					setBtn2Loading(false);
					setErrorMessage("");
					setLoginData({
						email: "",
						otp: "",
					});
					setModeratorToken(result.data.data.access_token);
					const expirationDate = new Date();
					expirationDate.setTime(
						expirationDate.getTime() + 24 * 60 * 60 * 1000
					); // 24 hours
					cookies.set(
						"moderatorToken",
						result.data.data.access_token,
						{ path: "/", expires: expirationDate }
					);
					
				}
			} catch (err) {
				logger.error(err);
				setErrorMessage(err.response.data.message);
				setBtn2Loading(false);
			}
		} else {
			setBtn2Loading(false);
			setBtnLoading(false);
		}
	};

	useEffect(() => {
		if (moderatorToken !== "" && errorMessage === "") {
			setShowCreateConversationIdBtn(true);
		}
	}, [moderatorToken]);

	const getConversationId = async () => {
		setLoading(true);
		try {
			let data = {
				title: "What are the emotional drivers behind purchases that are not basic needs? ",
				description: "test",
				datetime: "2024/11/10 02:00 PM",
			};
			var res = await axios.post(
				`${process.env.REACT_APP_API_BASE_URL}/conversation`,
				data,
				{
					headers: {
						Authorization: `Bearer ${moderatorToken}`,
					},
				}
			);
			if (res.data.status_code === 200) {
				setLoading(false);
				// setDescription('')
				setErrorMessage("");
				setCreateConversationId(`${process.env.REACT_APP_PARTICIPANT_URL}/?conversation_id=${res.data.data.id}`);
			}
		} catch (err) {
			logger.error(err);
			// setShowCreateConversationIdBtn(false);
			// setOtp(false);
			setErrorMessage(err.response.data.message);
			setLoading(false);
		}
	};

	return (
		<>
			{redirectToRegistration ? (
				<Registration
					redirectToRegistration={redirectToRegistration}
					setRedirectToRegistration={setRedirectToRegistration}
				/>
			) : (
				<div className="login__section">
					<div className="login">
						<div className="login__logo">
							<img
								src={`${publicUrl}/assets/images/logo.png`}
								alt="Logo"
								className="logo"
								width="237px"
								height="69px"
							/>
						</div>
						<div className="login__content">
							<div className="form login__form">
								{!showCreateConversationIdBtn && (
									<h4 className="login__form-title text-center">
										Login with Email
									</h4>
								)}
								<form onSubmit={handleSubmit}>
									{!showCreateConversationIdBtn && (
										<>
											<div className="form__row">
												<label
													htmlFor="inputemail"
													className="form__label"
												>
													Email
												</label>
												<input
													type="email"
													name="email"
													className="form__input"
													id="inputemail"
													onChange={handleDataChange}
													required
												/>
											</div>
											{otp && (
												<div className="form__row">
													<label
														htmlFor="inputpassword"
														className="form__label"
													>
														OTP
													</label>
													<input
														type="text"
														name="otp"
														className="form__input"
														id="inputpassword"
														onChange={
															handleDataChange
														}
														required
													/>
												</div>
											)}
										</>
									)}

									{/* <div className="form__row form__row--2column">
										<div className="form__column form__column-inline form__column--checkbox">
											<input
												className="form__checkbox"
												type="checkbox"
												id="rememberme"
												required
											/>
											<label
												className="form__label"
												htmlFor="rememberme"
											>
												Remember Me
											</label>
										</div>
										<div className="form__column form__column--forgot">
											<a href="/">Forgot Password?</a>
										</div>
									</div> */}
									{!showCreateConversationIdBtn && (
										<div className="form__row form__row--submit">
											<>
												{otp ? (
													<Button
														htmlType="submit"
														loading={btn2Loading}
														className="button button--block"
													>
														Login
													</Button>
												) : (
													<Button
														htmlType="submit"
														loading={btnLoading}
														className="button button--block"
													>
														Send OTP
													</Button>
												)}
											</>
										</div>
									)}
								</form>
								{showCreateConversationIdBtn && (
									<>
										{/* <div className="form__row">
												<label
													htmlFor="description"
													className="form__label"
												>
													Description
												</label>
												<input
													type="text"
													name="description"
													className="form__input"
													id="description"
													onChange={handleDescription}
													required
												/>
											</div> */}

										<div className="btn_block_3">
											<div className="text-center">
												<Button
													className="button button--block button--block2"
													loading={loading}
													onClick={getConversationId}
												>
													Create Conversation
												</Button>
											</div>
											{createConversationId !== "" && (
												<>
													<div className="text-center">
														<Button
															className="button button--block button--block2"
															onClick={copyLink}
														>
															{linkCopied
																? "Link Copied"
																: "Copy Link"}
														</Button>
													</div>
												</>
											)}
										</div>
									</>
								)}
								<div className="text-center msg_box">
									{/* {!emailValid && <p className="error-msg note-msg">Invalid email format.</p>} */}
									{errorMessage && (
										<p className="error-msg note-msg">
											{errorMessage}
										</p>
									)}
								</div>

								{showCreateConversationIdBtn && (
									<>
										<div className="text-center msg_box">
											<p className="linkText note-msg">
												{createConversationId}
											</p>
										</div>
										<input
											value={createConversationId}
											ref={inputRef}
											type="hidden"
										></input>

										<div className="text-center">
											<Button
												className="button button--block button--block2 logout_btn"
												loading={btn3Loading}
												onClick={handleLogout}
											>
												Logout
											</Button>
										</div>
									</>
								)}
								<div className="text-center msg_box">
									<p className="success-msg note-msg">
										{otpMessage}
									</p>
								</div>
							</div>

							{!showCreateConversationIdBtn && (
								<div className="login__create">
									Don’t have an account yet?{" "}
									<span
										style={{
											cursor: "pointer",
											textDecoration: "underline",
										}}
										onClick={handleRegistration}
									>
										Create an account
									</span>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

const Registration = ({
	redirectToRegistration,
	setRedirectToRegistration,
}) => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
	const [registerEmail, setRegisterEmail] = useState("");
	const [signupMsg, setsignupMsg] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [btnLoading, setBtnLoading] = useState(false);
	const [emailValid, setEmailValid] = useState(false);

	const handleLogin = () => {
		setRedirectToRegistration(false);
	};

	const handleEmailChange = (event) => {
	
		let emailValue = event.target.value;
		if (emailRegex.test(emailValue) === true) {
			setRegisterEmail(emailValue);
			setErrorMessage("");
		} else {
			setRegisterEmail(emailValue);
			setErrorMessage("Please enter valid email address.");
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (registerEmail !== "" && emailRegex.test(registerEmail) === true) {
			setBtnLoading(true);
			try {
				let data = {
					email: registerEmail,
				};
				var res = await axios.post(
					`${process.env.REACT_APP_API_BASE_URL}/moderator`,
					data,
					{}
				);
				if (res.data.status_code === 200) {
					setBtnLoading(false);
					setErrorMessage("");
					setsignupMsg("Signup Successfully");
					setTimeout(() => {
						handleLogin();
					}, 3000);
				}
			} catch (err) {
				logger.error(err);
				setBtnLoading(false);
				setErrorMessage(err.response.data.message);
				setBtnLoading(false);
			}
		} else {
			setBtnLoading(false);
			setErrorMessage("Please enter valid email address.");
		}
	};

	return (
		<div className="login__section">
			<div className="login">
				<div className="login__logo">
					<img
						src={`${publicUrl}/assets/images/logo.png`}
						alt="Logo"
						className="logo"
					/>
				</div>
				<div className="login__content">
					<div className="form login__form">
						<h4 className="login__form-title text-center">
							Signup with Email
						</h4>
						<form onSubmit={handleSubmit}>
							<div className="form__row">
								<label
									htmlFor="inputemail"
									className="form__label"
								>
									Email
								</label>
								<input
									type="email"
									className="form__input"
									id="inputemail"
									name="email"
									onChange={handleEmailChange}
									required
								/>
							</div>
							<div className="form__row form__row--submit">
								<Button
									htmlType="submit"
									className="button button--block"
									loading={btnLoading}
								>
									Sign up
								</Button>
							</div>
						</form>
						<div className="text-center">
							{errorMessage && (
								<p className="error-msg note-msg">
									{errorMessage}
								</p>
							)}
							{signupMsg && (
								<p className="success-msg note-msg">
									{signupMsg}
								</p>
							)}
						</div>
					</div>

					<div className="login__create">
						Already have an account? {/* <a href="/">Login</a> */}
						<span
							style={{
								cursor: "pointer",
								textDecoration: "underline",
							}}
							onClick={handleLogin}
						>
							Login
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModeratorLogin;
