import React from "react";
import "./theme.css";

function GeneratedTheme({ themes }) {
	return (
		<div className="theme">
			<h6 className="theme__title">Generated Themes</h6>
			<ul>
				{themes.map(theme => (
					<li key={theme.id}>{theme.name}</li>
				))}
			</ul>
		</div>
	);
}

export default GeneratedTheme;
