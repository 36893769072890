import React, { useContext, useEffect, useState, useReducer } from "react";
import Sidebar from "../sidebar/Sidebar";
import "../../css/form.css";
import axios from "axios";
import { io } from 'socket.io-client';
import "./conversations.css";
import { format } from 'date-fns';
import { isLogin } from "../commonComponents/authUtils";
import stateContext from "../../context/stateGlobal/StateContext";
import { useNavigate } from "react-router-dom";
import { logger } from "../utils/Logger";
import ModeratorProfile from "../profileDropdown/ModeratorProfile";
import { Button, Modal, Popconfirm, Spin } from "antd";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import MenuIcon from '@mui/icons-material/Menu';
import { LocalDiningOutlined } from "@mui/icons-material";

const formatDate = (dateString) => {
	const date = new Date(dateString)

	const options = {
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		hour12: true,
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
	};

	return new Intl.DateTimeFormat('en-GB', options).format(date);
};

const epochToDateTime = (epoch) => {
	const dateObj = new Date(epoch * 1000); // Convert seconds to milliseconds
	return dateObj.toLocaleString()
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_CONVERSATIONS':
			return { ...state, data: action.payload };
		case 'ADD_CONVERSATION':
			const updatedList = [...state.data, action.payload];
			const sortedData = updatedList.sort((a, b) => b.created_at - a.created_at);
			return { ...state, data: sortedData };
		case 'DELETE_CONVERSATION':
			return { ...state, data: state.data.filter(item => item.id !== action.payload) };
		default:
			return state;
	}
};

function Conversations() {
	const token = isLogin()
	const navigate = useNavigate();
	const currentDateTime = dayjs();
	let globalState = useContext(stateContext);
	const [errorMessage, setErrorMessage] = useState("");
	const [errorMessage2, setErrorMessage2] = useState("");
	const [currentDate, setCurrentDate] = useState(new Date());
	const [conversationList, setConversationList] = useState([]);
	const [loading, setLoading] = useState(true);
	// const [currentDateTime, setCurrentDateTime] = useState('');
	const [linkCopied, setLinkCopied] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(null);
	const [exportLoading, setExportLoading] = useState(null);
	const [createConversationId, setCreateConversationId] = useState("");
	const [btnLoading, setBtnLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedDateTime, setSelectedDateTime] = useState(null);
	// const [errorMessage, setSelectedDateTime] = useState(null);
	const [conversationData, setConversationData] = useState({
		title: "",
		description: "",
	});
	const [state, dispatch] = useReducer(reducer, { data: [] });

	useEffect(() => {
		fetchConversations()
	}, []);

	const fetchConversations = async () => {
		if (token) {
			try {
				var res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/conversation`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				if (res.data.status_code === 200) {
					setLoading(false)
					const conversationList = res.data.data
					if (conversationList.length > 0) {
						const sortedData = conversationList
						sortedData.sort((a, b) => b.created_at - a.created_at);
						// setConversationList(sortedData)
						dispatch({ type: 'FETCH_CONVERSATIONS', payload: sortedData });
					} else {
						setConversationList([])
					}
				} else {

				}
			} catch (err) {
				logger.error(err);
				setErrorMessage(err.response.data.message)
			}
		} else {
			navigate("/");
		}
	}

	const showConversationModal = () => {
		setErrorMessage2("")
		setIsModalOpen(true);
	};
	//   const handleOk = () => {
	// 	setIsModalOpen(false);
	//   };
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setConversationData((prevLoginData) => ({
			...prevLoginData,
			[name]: value,
		}));
	};

	const handleDateTimeChange = (newDateTime) => {
		const formattedDateTime = dayjs(newDateTime).format('YYYY/MM/DD hh:mm A');
		setSelectedDateTime(formattedDateTime)
		setErrorMessage2("")
	}

	const handleDetailSubmit = (event) => {
		event.preventDefault();
		createConversation();
	}

	const createConversation = async () => {
		logger.log(currentDateTime)
		setBtnLoading(true);
		if (selectedDateTime === null) {
			setErrorMessage2("Date is required");
			setBtnLoading(false);
			return
		}
		setLoading(true);

		try {
			let data = {
				title: conversationData.title,
				description: conversationData.description !== "" ? conversationData.description : "Test",
				datetime: selectedDateTime ? selectedDateTime : "2024/12/08 07:01 PM",
			};
			var res = await axios.post(
				`${process.env.REACT_APP_API_BASE_URL}/conversation`, data,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (res.data.status_code === 200) {
				setConversationData(({
					title: "",
					description: "",
				}))
				setSelectedDateTime(null)
				setIsModalOpen(false);
				setBtnLoading(false);
				setLoading(false);
				dispatch({ type: 'ADD_CONVERSATION', payload: res.data.data });
				// setDescription('')
				setErrorMessage("");
				setCreateConversationId(`${process.env.REACT_APP_PARTICIPANT_URL}/?conversation_id=${res.data.data.id}`);
			}
		} catch (err) {
			logger.error(err);
			// setShowCreateConversationIdBtn(false);
			// setOtp(false);
			setErrorMessage(err.response.data.message);
			setBtnLoading(false);
			setLoading(false);
		}
	};

	const startConversation = async (id) => {
		globalState.setSelectedConversation(id)
		if (id) {
			navigate(`/conversations/${id}`)
		}
	}

	const ExportConversationDetails = async (id, title, dateTime) => {
		setExportLoading(id)
		const excelTitle = title;
		const conversationDate = dateTime;

		const excelDate = new Date(conversationDate);

		const months = [
			"Jan", "Feb", "Mar", "Apr", "May", "Jun",
			"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
		];

		const formattedDate = `${excelDate.getDate()}_${months[excelDate.getMonth()]}_${excelDate.getFullYear().toString()}`;
		const formattedTitle = excelTitle.replace(/\s+/g, '_');

		try {
			var res = await axios.get(
				`${process.env.REACT_APP_EXPORT_EXCEL_URL}/conversation/${id}`,
				{
					responseType: 'arraybuffer',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (res.status === 200) {
				if (res.status === 200) {
					const contentDisposition = res.headers.get('content-disposition')
					let fileName;
					if (contentDisposition && contentDisposition.length > 1) {
						const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
						const matches = contentDisposition.match(filenameRegex);
						fileName = matches[1].trim().replace(/['"]/g, '');
					} else {
						const formattedDate = `${excelDate.getDate()}_${months[excelDate.getMonth()]}_${excelDate.getFullYear().toString()}`;
						const formattedTitle = excelTitle.replace(/\s+/g, '_');
						fileName = `${formattedTitle}_${formattedDate}.xlsx`;
					}
					const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = fileName;
					link.click();
					logger.log("successfully downloaded")
					setExportLoading(null)
				}
			}
		} catch (err) {
			logger.error(err);
			setExportLoading(null)
			setErrorMessage(err.response.data.message);
		}
	}

	const deleteConversation = async (id) => {
		// setLoading(true);
		setDeleteLoading(id);
		try {
			var res = await axios.delete(
				`${process.env.REACT_APP_API_BASE_URL}/conversation/${id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (res.data.status_code === 200) {
				setDeleteLoading(null);
				// fetchConversations();
				dispatch({ type: 'DELETE_CONVERSATION', payload: id });
			}
		} catch (err) {
			logger.error(err);
			setErrorMessage(err.response.data.message);
			setLoading(false);
			setDeleteLoading(null);
		}
	}

	const copyLink = (id, index) => {
		let linkData = `${process.env.REACT_APP_PARTICIPANT_URL}/?conversation_id=${id}`
		// Assuming createConversationId is a string
		const copyText = linkData;

		// Create a temporary textarea element
		const textarea = document.createElement("textarea");
		textarea.value = copyText;

		document.body.appendChild(textarea);

		textarea.select();

		// Execute the copy command
		document.execCommand("copy");

		// Remove the textarea from the document
		document.body.removeChild(textarea);

		setLinkCopied(index);

		setTimeout(() => {
			setLinkCopied(null);
		}, 3000);
	};

	const openSidebar = () => {
		globalState.setOpenSidebar(false)
	}

	return (
		<div className="conversations innerPage innerPage--sidebar">
			<Sidebar />

			<div className="pageContent">
				<div className="pageContent__header">
					<button className="button button--small button--green" onClick={showConversationModal}>
						Create Conversation
					</button>
					<div className="open__sidebar">
						<MenuIcon onClick={openSidebar} />
					</div>
					<ModeratorProfile />
				</div>
				<div className="pageContent__body">
					<h4 className="pageContent__body-title">
						Conversations
					</h4>
					<div className="conversations__list conversations__list--scroll">
						{loading ? (
							<p color="#000">Loading.....</p>
						) : (
							<>
								{state.data && state.data.length > 0 ? (
									<>
										{state.data.map((conversation, index) => (
											// <div className={`${conversation.datetime === currentDate ? "conversations__item" : "conversations__item_old"}>
											<div className="conversations__item" key={index}>

												<div className="conversations__item-details">
													<h5 className="conversations__item-title">
														{conversation.title}
													</h5>
													<div className="conversations__item-date">
														{conversation.datetime}
														{/* Created At : {epochToDateTime(conversation.created_at)} */}
													</div>
													<div className="conversations__item-note
													">
														{/* Id: {conversation.id} */}
														Link: {`${process.env.REACT_APP_PARTICIPANT_URL}/?conversation_id=${conversation.id}`}
													</div>
												</div>
												<div className="conversations__item-actions">
													<button className="conversations__item-start button button--small button--gold" onClick={() => copyLink(conversation.id, index)}>
														{linkCopied === index
															? "Link Copied"
															: "Copy Link"}
													</button>

													{/* <button className="conversations__item-start button button--small button--gold" onClick={() => startConversation(conversation.id)} disabled={conversation.end_at ? true : false}> */}
													{!conversation.end_at && (
														<button className="conversations__item-start button button--small button--gold" onClick={() => startConversation(conversation.id)}>
															Connect Conversation
														</button>
													)}


													{conversation.end_at && (
														<button className={`conversations__item-start button button--small button--gold ${exportLoading === conversation.id ? "export_loading_btn" : ""}`} onClick={() => ExportConversationDetails(conversation.id, conversation.title, conversation.datetime)}>
															{exportLoading === conversation.id ? <span className="delete__loader"></span> : "Export"}
														</button>
													)}

													<button className="conversations__item-delete button button--small button--icon-only" onClick={() => deleteConversation(conversation.id)}>
														{deleteLoading === conversation.id ? <span className="delete__loader"></span>
															:
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
															>
																<path
																	d="M20.25 4.5H16.5V3.75C16.5 3.15326 16.2629 2.58097 15.841 2.15901C15.419 1.73705 14.8467 1.5 14.25 1.5H9.75C9.15326 1.5 8.58097 1.73705 8.15901 2.15901C7.73705 2.58097 7.5 3.15326 7.5 3.75V4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25C3 5.44891 3.07902 5.63968 3.21967 5.78033C3.36032 5.92098 3.55109 6 3.75 6H4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V6H20.25C20.4489 6 20.6397 5.92098 20.7803 5.78033C20.921 5.63968 21 5.44891 21 5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5ZM9 3.75C9 3.55109 9.07902 3.36032 9.21967 3.21967C9.36032 3.07902 9.55109 3 9.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75V4.5H9V3.75ZM18 19.5H6V6H18V19.5ZM10.5 9.75V15.75C10.5 15.9489 10.421 16.1397 10.2803 16.2803C10.1397 16.421 9.94891 16.5 9.75 16.5C9.55109 16.5 9.36032 16.421 9.21967 16.2803C9.07902 16.1397 9 15.9489 9 15.75V9.75C9 9.55109 9.07902 9.36032 9.21967 9.21967C9.36032 9.07902 9.55109 9 9.75 9C9.94891 9 10.1397 9.07902 10.2803 9.21967C10.421 9.36032 10.5 9.55109 10.5 9.75ZM15 9.75V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5C14.0511 16.5 13.8603 16.421 13.7197 16.2803C13.579 16.1397 13.5 15.9489 13.5 15.75V9.75C13.5 9.55109 13.579 9.36032 13.7197 9.21967C13.8603 9.07902 14.0511 9 14.25 9C14.4489 9 14.6397 9.07902 14.7803 9.21967C14.921 9.36032 15 9.55109 15 9.75Z"
																	fill="black"
																/>
															</svg>
														}

													</button>



												</div>
											</div>
										))}
									</>
								) : <div>No conversation found please new one.</div>}
							</>
						)}
					</div>
					<p className="message_text error_msg_text mt-2">
						{errorMessage}
					</p>
				</div>
			</div>

			<Modal title={<span className="modal__title">Create Conversation</span>} open={isModalOpen} footer={null} centered={true} closable={false}>
				<form onSubmit={handleDetailSubmit} className="conversation_form">
					<div className="form__row">
						<label
							htmlFor="title"
							className="form__label"
						>
							Title
						</label>
						<input
							type="text"
							className="form__input"
							id="title"
							name="title"
							value={conversationData.title}
							onChange={handleChange}
							required
						/>
					</div>
					<div className="form__row">
						<label
							htmlFor="description"
							className="form__label"
						>
							Description
						</label>
						<textarea
							className="form__input"
							id="description"
							name="description"
							value={conversationData.description}
							rows={3}
							onChange={handleChange}
						// required
						/>
					</div>
					<div className="form__row date__row">
						<label
							htmlFor="dateTime"
							className="form__label"
						>
							Date (EST Time Zone)
						</label>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DemoContainer components={['DateTimePicker']}>
								<DateTimePicker
									label=""
									// minDateTime={currentDateTime}
									value={selectedDateTime}
									name="dateTime"
									onChange={(newDateTime) => handleDateTimeChange(newDateTime)}
								/>
							</DemoContainer>
						</LocalizationProvider>

						{/* </DemoContainer> */}
						{/* // <textarea
								// 	className="form__input"
								// 	id="dateTime"
								// 	name="dateTime"
								// 	rows={3}
								// 	onChange={handleChange}
								// /> */}
					</div>
					<p className="message_text error_msg_text mt-2">
						{errorMessage2}
					</p>


					<div className="flexBox">
						<Button
							htmlType="submit"
							className="button button--gold button--small"
							loading={btnLoading}
						>
							Create
						</Button>
						<Button
							className="button button--small"
							onClick={handleCancel}
						>
							Cancel
						</Button>
					</div>
				</form>
			</Modal>
		</div>
	);
}

export default Conversations;
