import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Conversations from "./components/conversations/Conversations";
import ConversationDetail from "./components/conversations/ConversationDetail";
import ModeratorLogin from "./components/login/ModeratorLogin";

function App() {
	return (
		<>
			<Router>
				<Routes>
					<Route path="/" element={<ModeratorLogin />}></Route>
					<Route path="/conversations" element={<Conversations />}></Route>
					<Route path="/conversations/:conversation_id" element={<ConversationDetail />}></Route>
					{/* Catch-all route for undefined paths */}
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</Router>
		</>
	);
}

export default App;
