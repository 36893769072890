import { get_name_by_question_type } from '../components/utils/types';

export class QuestionModel {
    QuestionType;
    SectionNumber;
    Name;
    Time;

    constructor(data, section_number = '') {
        this.Id = data.id;
        this.QuestionType = data.question_type;
        this.Name = get_name_by_question_type(data.question_type);
        this.SectionNumber = section_number;
        this.Time = data.timer;
    }
}