import React, {useContext, useState} from "react";
import "./sidebar.css";
import { Link } from "react-router-dom";
import stateContext from "../../context/stateGlobal/StateContext";
import CloseIcon from '@mui/icons-material/Close';

let publicUrl = process.env.PUBLIC_URL;

function Sidebar() {
	let globalState = useContext(stateContext);

	const closeSidebar = () => {
		globalState.setOpenSidebar(true)
	}

	return (
		<div className={`sidebar ${globalState.openSidebar ? "closed" : ""}`}>
			<div className="toggle__close" onClick={closeSidebar}>
				<CloseIcon />
			</div>
			<div className="sidebar__logo">
				<img
					src={`${publicUrl}/assets/images/logo.png`}
					alt="Logo"
					className="logo"
					width="237px"
					height="69px"
				/>
			</div>
			<nav className="sidebar__nav">
				<ul>
					<li>
						<Link to="/conversations" className="sidebar__nav-link active">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
							>
								<path
									d="M16.5 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V16.5C0 16.8978 0.158035 17.2794 0.43934 17.5607C0.720644 17.842 1.10218 18 1.5 18H16.5C16.8978 18 17.2794 17.842 17.5607 17.5607C17.842 17.2794 18 16.8978 18 16.5V1.5C18 1.10218 17.842 0.720644 17.5607 0.43934C17.2794 0.158035 16.8978 0 16.5 0ZM13.5 7.5V16.5H9.75V7.5H13.5ZM8.25 16.5H4.5V7.5H8.25V16.5ZM16.5 16.5H15V6.75C15 6.55109 14.921 6.36032 14.7803 6.21967C14.6397 6.07902 14.4489 6 14.25 6H3.75C3.55109 6 3.36032 6.07902 3.21967 6.21967C3.07902 6.36032 3 6.55109 3 6.75V16.5H1.5V1.5H16.5V16.5ZM11.25 3.75C11.25 3.94891 11.171 4.13968 11.0303 4.28033C10.8897 4.42098 10.6989 4.5 10.5 4.5H7.5C7.30109 4.5 7.11032 4.42098 6.96967 4.28033C6.82902 4.13968 6.75 3.94891 6.75 3.75C6.75 3.55109 6.82902 3.36032 6.96967 3.21967C7.11032 3.07902 7.30109 3 7.5 3H10.5C10.6989 3 10.8897 3.07902 11.0303 3.21967C11.171 3.36032 11.25 3.55109 11.25 3.75Z"
									fill="currentColor"
								/>
							</svg>
							Moderator Room
						</Link>
					</li>
					{/* <li>
						<a href="/invites" className="sidebar__nav-link">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
							>
								<path
									d="M21.3113 2.68876C21.1226 2.50025 20.8872 2.36544 20.6291 2.29821C20.3711 2.23098 20.0998 2.23376 19.8431 2.30626H19.8291L1.83469 7.76625C1.54178 7.85049 1.28147 8.02196 1.08844 8.25784C0.895411 8.49371 0.778814 8.78278 0.754177 9.08658C0.72954 9.39037 0.798032 9.69445 0.950532 9.95835C1.10303 10.2222 1.3323 10.4334 1.60782 10.5638L9.63469 14.3653L13.4363 22.3922C13.5563 22.6492 13.7473 22.8666 13.9869 23.0186C14.2264 23.1706 14.5044 23.2509 14.7881 23.25C14.8313 23.25 14.8744 23.2481 14.9175 23.2444C15.2202 23.2199 15.5082 23.1034 15.7428 22.9105C15.9774 22.7176 16.1474 22.4576 16.23 22.1653L21.6863 4.17094C21.6863 4.16626 21.6863 4.16157 21.6863 4.15688C21.7597 3.90091 21.7637 3.63 21.6978 3.37199C21.6319 3.11397 21.4985 2.87815 21.3113 2.68876ZM14.7966 21.7359L14.7919 21.7491L11.1019 13.9594L15.5306 9.52969C15.6653 9.3879 15.7393 9.19908 15.7368 9.00351C15.7343 8.80794 15.6555 8.62108 15.5172 8.48278C15.3789 8.34448 15.1921 8.26568 14.9965 8.26318C14.8009 8.26067 14.6121 8.33466 14.4703 8.46938L10.0406 12.8981L2.25001 9.20813H2.26313L20.25 3.75001L14.7966 21.7359Z"
									fill="currentColor"
								/>
							</svg>
							Invites
						</a>
					</li> */}
				</ul>
			</nav>
		</div>
	);
}

export default Sidebar;
