// export const question_types = {
//     "A": "THEME ANALYSIS",
//     "B": "JUSTIFICATION – OF SELECTION",
//     "D1": "THEME VALIDATION",
//     "D2": "SUMMARY VALIDATION",
//     "E": "THEME SELECT ALL",
//     "E1": "THEME SELECT ALL",
//     "E2": "MOST IMPORTANT",
//     "F": "RATING SCALE one item (pre-selected)",
//     "G": "SINGLE SELECT",
//     "O": "OPEN-ENDED",
//     "S": "SINGLE SELECT",
// }

export const question_types = {
    "A": "Theme Analysis",
    "B": "Justification – Of Selection",
    "D1": "Theme Validation",
    "D2": "Summary Validation",
    "E": "Theme Select All",
    "E1": "Theme Select All",
    "E2": "Most Important",
    "F": "Rating Scale One Item (Pre-selected)",
    "G": "Single Select",
    "O": "Open-ended",
    "S": "Single Select",
    "PD": "Participant Demographics",
};

export const get_name_by_question_type = (question_type) => {
    if (!question_type)
        return '';

    question_type = question_type.toUpperCase();

    if (question_type.startsWith('G')) {
        return question_types['G'];
    } else {
        return question_types[question_type];
    }
}
