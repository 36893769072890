import Cookies from 'universal-cookie';
const cookies = new Cookies();

export function isLogin() {
    var moderator_token = cookies.get('moderatorToken', { path: '/' });
    if (moderator_token && moderator_token !== "null" && moderator_token !== "undefined") {
        return moderator_token
    }
    else {
        return null
    }
}


