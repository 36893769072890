import React, { useContext } from "react";
import "./moderatorProfile.css";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import stateContext from "../../context/stateGlobal/StateContext";

let publicUrl = process.env.PUBLIC_URL;
const cookies = new Cookies();

function ModeratorProfile({page}) {
	const navigate = useNavigate();
	let globalState = useContext(stateContext);
	const handleLogout = (event) => {
		cookies.remove("moderatorToken", { path: "/" });
		navigate("/");
	}

	const backToConversations = () =>{
		navigate('/conversations')
	}
	const openSidebar = () => {
		globalState.setOpenSidebar(false)
	}
	
	return (
		<div className="pageContent__header_inner">
			{page === "detail" && (
				<>
					<div className="backIconBox__main">
						<span onClick={backToConversations} className="backIconBox"><ArrowBackIcon/> Back</span>
					</div>
					<div className="open__sidebar">
						<MenuIcon onClick={openSidebar}/>
					</div>
				</>
			)}
			
			<div className="profile-dropdown">
			{/* <img
				src={`${publicUrl}/assets/images/profile.png`}
				alt="profile"
				className="profile-dropdown__icon"
				width="40px"
				height="40px"
			/>
			<span className="profile-dropdown__name">Kevin Foster</span> */}
	
			<div className="logout_box">
				<LogoutIcon />
				<span className="profile-dropdown__name" onClick={handleLogout}>Logout</span>
			</div>
			
		</div>
		</div>
		
	);
}

export default ModeratorProfile;
