import React, { useContext, useEffect, useState, useReducer } from "react";
import Sidebar from "../sidebar/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import GeneratedTheme from "../theme/GeneratedTheme";
import axios from "axios";
import { io } from "socket.io-client";
import "./conversations.css";
import "../summary/generatedSummary.css";
import stateContext from "../../context/stateGlobal/StateContext";
import { isLogin } from "../commonComponents/authUtils";
import { logger } from "../utils/Logger";
import GeneratedSummary from "../summary/generatedSummary";
import ModeratorProfile from "../profileDropdown/ModeratorProfile";
import LoadTimer from "../loader/LoadTimer";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import WarningIcon from "@mui/icons-material/Warning";
import { Modal } from "antd";
import { QuestionModel } from "../../models/QuestionModel";

const reducer = (state, action) => {
  switch (action.type) {
    case eventAction.ADD_SUMMARY:
      const existingIndex = state.generatedSummary.some(
        (item) => item.id === action.data.theme.id
      );

      if (existingIndex) {
        return state;
      } else {
        return {
          ...state,
          generatedSummary: [
            ...state.generatedSummary,
            {
              id: action.data.theme.id,
              theme: action.data.theme.name,
              summary: action.data.summary,
            },
          ],
        };
      }

    case eventAction.ADD_PARTICIPANTS:
      console.log("ADD_PARTICIPANTS");
      console.log(action.data);
      let temp = {};
      action.data.map((item) => {
        temp[item.participant] = item.first_name;
      });
      return {
        ...state,
        connectedParticipants: temp,
      };
    case eventAction.ADD_PARTICIPANT:
      console.log("ADD_PARTICIPANT");
      console.log(action.data);
      if (!state.connectedParticipants[action.data]) {
        state.connectedParticipants[action.data] = action.data.first_name;
        return {
          ...state,
          connectedParticipants: state.connectedParticipants,
        };
      }
      break;
    case eventAction.REMOVE_PARTICIPANT:
      console.log("REMOVE_PARTICIPANTS");
      console.log(action.data);

      let connectedItems = { ...state.connectedParticipants };
      let itemToRemove = action.data;
      if (itemToRemove) {
        delete connectedItems[itemToRemove];
      }

      console.log(connectedItems);

      return {
        ...state,
        connectedParticipants: { ...connectedItems },
      };

    default:
      return state;
  }
};

const eventAction = {
  ADD_SUMMARY: "ADD_SUMMARY",
  ADD_PARTICIPANTS: "ADD_PARTICIPANTS",
  ADD_PARTICIPANT: "ADD_PARTICIPANT",
  REMOVE_PARTICIPANT: "REMOVE_PARTICIPANT",
};

function ConversationDetail() {
  const { conversation_id } = useParams();
  let globalState = useContext(stateContext);
  const token = isLogin();
  const navigate = useNavigate();
  const [conversationDataState, setConversationDataState] = useState(false);
  const [conversationData, setConversationData] = useState({
    id: "",
    title: "",
    date: "",
  });

  const [questions, setQuestions] = useState([]);
  const [linkCopied, setLinkCopied] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(new QuestionModel({}));
  const [currentSection, setCurrentSection] = useState("s0");
  const [connectedParticipants, setConnectedParticipants] = useState(0);
  const [waitingParticipants, setWaitingParticipants] = useState(new Set());
  const [disconnectedParticipants, setDisconnectedParticipants] = useState(
    new Set()
  );
  const [removedParticipants, setRemovedParticipants] = useState(new Set());
  const [acceptedLegalParticipants, setAcceptedLegalParticipants] = useState(0);
  const [gotItConfirmationParticipants, setGotItConfirmationParticipants] =
    useState(0);
  const [
    completeConversationParticipants,
    setCompleteConversationParticipants,
  ] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [generatedThemes, setGeneratedThemes] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(
    new QuestionModel({})
  );

  const [state, dispatch] = useReducer(reducer, {
    generatedSummary: [],
    connectedParticipants: {},
  });

  useEffect(() => {
    if (token) {
      fetchSelectedConversationDetail();
      fetchQuestions();
    } else {
      navigate("/");
    }
  }, []);

  const fetchSelectedConversationDetail = async () => {
    try {
      var res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/conversation/${conversation_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      logger.log(res);
      if (res.data.status_code === 200) {
        const data = res.data.data;
        if (Object.keys(data).length !== 0) {
          logger.log(data);
          setConversationData({
            id: data.id,
            date: data.datetime,
            title: data.title,
          });
          setConversationDataState(true);
        }
      } else {
      }
    } catch (err) {
      logger.error(err);
      // setErrorMessage(err.response.data.message)
    }
  };

  function mapQuestionsToQuestionModel(data) {
    return data.map((section) => ({
      section_number: section.section_number,
      questions: section.questions.map(
        (question) => new QuestionModel(question, section.section_number)
      ),
    }));
  }

  function convertToUpperCase(str) {
    return str ? str.toUpperCase() : "";
  }

  const fetchQuestions = async () => {
    try {
      var res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/question?conversation_id=${conversation_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data.status_code === 200) {
        const data = res.data.data;
        if (data.length > 0) {
          const mappedQuestions = mapQuestionsToQuestionModel(data);

          mappedQuestions.sort(
            (a, b) =>
              Number(a.section_number.substring(1)) -
              Number(b.section_number.substring(1))
          );

          mappedQuestions.push({
            section_number: "end",
            questions: [
              {
                Name: "Close conversation",
                QuestionType: "x",
                SectionNumber: "end",
              },
            ],
          });

          setQuestions(mappedQuestions);
          setSelectedQuestion(
            mappedQuestions[0].questions[0],
            mappedQuestions[0].section_number
          );
        }
      } else {
        alert("No Question Found");
      }
    } catch (err) {
      logger.error(err);
      // setErrorMessage(err.response.data.message)
    }
  };

  useEffect(() => {
    const socket = io(
      `${process.env.REACT_APP_WEB_SOCKET_URL}?access_token=${token}`
    );

    if (socket) {
      socket.on("connect", () => {
        logger.log("connected to server");
        globalState.setSocket(socket);
      });

      let questionResponse = {
        conversation_id: conversation_id,
        action: "join",
      };

      socket.emit("connect_conversation", questionResponse);

      socket.on("participants", (data) => {
        logger.log(data, "participants event received");
        dispatch({
          type: eventAction.ADD_PARTICIPANTS,
          data: data.participants,
        });
      });

      socket.on("timeout", (data) => {
        setRemovedParticipants(
          (prevSet) => new Set([...prevSet, data.participant])
        );
      });

      socket.on("participant", (data) => {
        logger.log(data, "participant event received");
        let action =
          data.action === "join"
            ? eventAction.ADD_PARTICIPANT
            : eventAction.REMOVE_PARTICIPANT;
        dispatch({ type: action, data: data.participant });

        if (
          data.action === "join" &&
          !state.connectedParticipants[data.participant.id]
        ) {
          setDisconnectedParticipants((prevSet) => {
            prevSet.delete(data.participant.id);
            return new Set([...prevSet]);
          });
        }
        if (data.action === "leave") {
          setWaitingParticipants((prevSet) => {
            prevSet.delete(data.participant.id);
            return new Set([...prevSet]);
          });
          setDisconnectedParticipants(
            (prevSet) => new Set([...prevSet, data.participant.id])
          );
        }
      });

      socket.on("message", (data) => {
        logger.log(data, "message");
      });

      socket.onAny((event, data) => {
        logger.log(event + " => " + JSON.stringify(data, null, 2));
      });

      socket.on("current_participant_screen", (data) => {
        logger.log(data, "current_participant_screen");
        if (
          data.section_number.toLowerCase() === "s0" &&
          data.question_type.toLowerCase() === "al"
        ) {
          setAcceptedLegalParticipants(
            (prevParticipants) => prevParticipants + 1
          );
        } else if (
          data.section_number.toLowerCase() === "s0" &&
          data.question_type.toLowerCase() === "cp"
        ) {
          setGotItConfirmationParticipants(
            (prevParticipants) => prevParticipants + 1
          );
        } else if (data.section_number.toLowerCase() === "s16") {
          setCompleteConversationParticipants(
            (prevParticipants) => prevParticipants + 1
          );
        }
      });

      socket.on("current_participant_response", (data) => {
        setWaitingParticipants(
          (prevSet) => new Set([...prevSet, data.participant])
        );
      });

      socket.on("themes", (data) => {
        logger.log(data, "themes");
        setGeneratedThemes(data.themes);
      });

      socket.on("summaries", (data) => {
        //dispatch({ type: eventAction.ADD_SUMMARY, data: data });
      });
    }
  }, []);

  const handleModalClose = () => {
    setBtnDisable(false);
    setAlertOpen(false);
  };

  const handleEventButton = async (question) => {
    if (!question) {
      logger.log("No question selected");
      return;
    }

    if (!question.SectionNumber || !question.QuestionType) {
      logger.log(
        "Invalid question. Section number or question type is missing"
      );
      return;
    }

    if (question.QuestionType === "x") {
      var plObj = { conversation_id: conversationData.id };

      globalState.socket.emit("close_conversation", plObj);
      navigate("/conversations");
      //TODO: maybe a message telling converstion closed ?
      return;
    }

    setWaitingParticipants(new Set());
    setCurrentSection(question.SectionNumber);

    debugger;

    const currentSectionQuestions = questions.find(
      (question) => question.section_number === selectedQuestion.SectionNumber
    )?.questions;

    let questionIndex = 0;
    if (selectedQuestion) {
      questionIndex = currentSectionQuestions.findIndex(
        (question) => question === selectedQuestion);
    }

    let nextSectionNumber = selectedQuestion.SectionNumber; // Default to the current section
    let nextQuestionIndex = questionIndex; // Default to the current question index

    if (question.QuestionType.toLowerCase() === "pd") {
      nextSectionNumber =
        "s" + (Number(question.SectionNumber.substring(1)) + 1);
      setCurrentSection(nextSectionNumber);
      nextQuestionIndex = 0; // Reset question index for new section
    } else {
      nextQuestionIndex = questionIndex + 1;

      if (nextQuestionIndex >= currentSectionQuestions.length) {
        // Move to the next section if no more questions in the current section
        nextSectionNumber = "s" + (Number(currentSection.substring(1)) + 1);
        setCurrentSection(nextSectionNumber);
        nextQuestionIndex = 0; // Reset question index for new section
      }
    }

    // Find the next question based on the updated section and question index
    const nextQuestion = questions.find(
      (section) => section.section_number === nextSectionNumber
    )?.questions[nextQuestionIndex];

    if (nextQuestion) {
      setSelectedQuestion(nextQuestion, nextSectionNumber);
      setCurrentQuestionIndex(nextQuestionIndex);
    }

    const nextQuestionTimer = (nextQuestion?.Time ?? 7) * 100; // Default to 7000 ms if no time

    setBtnDisable(true);
    setTimeout(() => {
      setBtnDisable(false);
    }, nextQuestionTimer);

    const questionPayload = {
      section_number: question.SectionNumber,
      question_type: question.QuestionType,
      data: {
        conversation_id: conversation_id,
      },
    };

    if (
      questionPayload.question_type?.toLowerCase().startsWith("e") ||
      questionPayload.question_type?.toLowerCase().startsWith("d")
    ) {
      if (globalState.socket) {
        globalState.socket.emit("generate_results", questionPayload);
      } else {
        logger.warn("Socket not connected");
      }
    }

    if (globalState.socket) {
      globalState.socket.emit("start_question", questionPayload);
    } else {
      logger.warn("Socket not connected");
    }

    logger.log(questionPayload, "handleEventButton - question payload");
  };

  const copyLink = (id) => {
    let linkData = `${process.env.REACT_APP_PARTICIPANT_URL}/?conversation_id=${id}`;
    // Assuming createConversationId is a string
    const copyText = linkData;

    // Create a temporary textarea element
    const textarea = document.createElement("textarea");
    textarea.value = copyText;

    document.body.appendChild(textarea);

    textarea.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textarea);

    setLinkCopied(true);

    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  };

  const displayQuestionName = (question) => {
    return question.Name;
  };

  return (
    <div className="conversations conversations--details innerPage innerPage--sidebar">
      <Sidebar />

      <div className="pageContent">
        <div className="pageContent__header">
          <ModeratorProfile page="detail" />
        </div>
        <div className="pageContent__body">
          <div className="conversations__list">
            <div className="conversations__item">
              <div className="conversations__item-details">
                {conversationDataState ? (
                  <>
                    <h5 className="conversations__item-title">
                      {conversationData.title}
                    </h5>
                    <div className="conversations__item-date">
                      {conversationData.date}
                    </div>
                    <div className="conversations__item-note">
                      {/* UUID: {conversationData.id} */}
                      <div className="flexBox">
                        <button
                          className="conversations__item-start button button--small button--gold"
                          onClick={() => copyLink(conversationData.id)}
                        >
                          {linkCopied ? "Link Copied" : "Copy Link"}
                        </button>
                        <span>{`${process.env.REACT_APP_PARTICIPANT_URL}/?conversation_id=${conversationData.id}`}</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="loadData_loader">Loading...</div>
                )}
              </div>
            </div>
          </div>
          <div className="conversations__columns">
            <div className="conversations__column conversations__column--timeline">
              {questions &&
                questions.length > 0 &&
                questions.map((section, sectionIndex) => (
                  <ul key={sectionIndex}>
                    <h3
                      className="section_number_title"
                      style={{ marginTop: sectionIndex == 0 ? 0 : "15px" }}
                    >
                      {convertToUpperCase(section.section_number)}
                    </h3>

                    {section.questions.some(
                      (question) => question.QuestionType.toLowerCase() === "pd"
                    ) ? (
                      <li
                        className={
                          section.questions[0] == selectedQuestion
                            ? "active"
                            : Number(section.section_number.substring(1)) <
                              Number(currentSection.substring(1))
                            ? "completed"
                            : ""
                        }
                        onClick={() => {
                          setSelectedQuestion(
                            section.questions[0],
                            section.section_number
                          );
                        }}
                      >
                        <div>{"Participant Demographics"}</div>
                      </li>
                    ) : (
                      // Render regular questions if no 'pd' question exists
                      section.questions.map((question, index) => {
                        if (question.QuestionType.toLowerCase() !== "pd") {
                          return (
                            <li
                              key={index}
                              className={
                                question == selectedQuestion
                                  ? "active"
                                  : index < currentQuestionIndex &&
                                    section.section_number === currentSection
                                  ? "completed"
                                  : ""
                              }
                              onClick={() => {
                                setSelectedQuestion(
                                  question,
                                  section.section_number
                                );
                              }}
                            >
                              <div>{displayQuestionName(question)}</div>
                            </li>
                          );
                        }
                      })
                    )}
                  </ul>
                ))}
            </div>
            <div className="conversations__column conversations__column--participants participants">
              <div className="participants_list_row">
                <h6 className="participants__title">Total Participants</h6>
                <span className="participants__count">
                  {Object.keys(state.connectedParticipants).length}
                </span>
              </div>
              <div className="participants_list_row">
                <h6 className="participants__title">
                  Accepted Legal Participants
                </h6>
                <span className="participants__count">
                  {acceptedLegalParticipants}
                </span>
              </div>
              <div className="participants_list_row">
                <h6 className="participants__title">
                  Awaiting Start Participants
                </h6>
                <span className="participants__count">
                  {gotItConfirmationParticipants}
                </span>
              </div>
              <div className="participants_list_row">
                <h6 className="participants__title">
                  Awaiting Moderator Next ACTION
                </h6>
                <span className="participants__count">
                  {waitingParticipants.size}
                </span>
              </div>
              <div className="participants_list_row">
                <h6 className="participants__title">
                  Dropped/Kicked out Participants
                </h6>
                <span className="participants__count">
                  {disconnectedParticipants.size}
                </span>
              </div>
              <div className="participants_list_row">
                <h6 className="participants__title">
                  Conversation Participants Completed
                </h6>
                <span className="participants__count">
                  {completeConversationParticipants}
                </span>
              </div>
            </div>
            <div className="conversations__column conversations__column--activity activity">
              <h6 className="activity__title">Current Activity Updates</h6>

              <div className="activity_inner_block">
                {Object.keys(state.connectedParticipants).length ===
                  disconnectedParticipants.size &&
                Object.keys(state.connectedParticipants).length !== 0 ? (
                  <LoadTimer
                    messageDetails={{
                      time: null,
                      endMessage: "Conversation Completed",
                    }}
                  />
                ) : (
                  <>
                    {state.generatedSummary &&
                    state.generatedSummary.length > 0 ? (
                      <LoadTimer
                        messageDetails={{
                          time: null,
                          endMessage: "Generated Summary",
                        }}
                      />
                    ) : (
                      <LoadTimer
                        messageDetails={{
                          time: selectedQuestion.Time,
                          text: "Wait while participants answer question",
                          endMessage: "You can now continue",
                        }}
                      />
                    )}
                  </>
                )}

                {generatedThemes.length > 0 && (
                  <GeneratedTheme themes={generatedThemes} type="theme" />
                )}

                {state.generatedSummary &&
                  state.generatedSummary.length > 0 && (
                    <GeneratedSummary summaryList={state.generatedSummary} />
                  )}
              </div>
              <div className="themeButtons">
                <button
                  className="button button--small button--gold button--assign"
                  disabled={btnDisable}
                  onClick={() => handleEventButton(selectedQuestion)}
                >
                  <TouchAppIcon />
                  {selectedQuestion.QuestionType === "x"
                    ? "Close conversation"
                    : `${selectedQuestion.SectionNumber?.toUpperCase() || ""}.${
                        selectedQuestion.QuestionType?.toUpperCase() || ""
                      }: ${selectedQuestion.Name || ""}`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={
          <span style={{ display: "flex", gap: "10px" }}>
            <h4 style={{ color: "red", marginBottom: "12px" }}>Warning</h4>
            <WarningIcon style={{ color: "red" }} />
          </span>
        }
        centered
        open={alertOpen}
        onOk={() => handleEventButton(selectedQuestion)}
        onCancel={() => handleModalClose()}
        maskClosable={false}
        cancelText="NO"
        okText="YES"
        okButtonProps={{ className: "button button--very--small button--gold" }}
        cancelButtonProps={{ className: "button button--very--small" }}
        className="alert-modal"
      >
        <h6 className="alertText">
          Currently,{" "}
          {Object.keys(state.connectedParticipants).length -
            disconnectedParticipants.size -
            waitingParticipants.size}{" "}
          number of participants are at risk of being removed. Click YES to
          proceed with the conversation, acknowledging the risk. Click NO to
          await their return on this screen.
        </h6>
      </Modal>
    </div>
  );
}

export default ConversationDetail;
