import React from 'react';
import { logger } from '../utils/Logger';
import './generatedSummary.css'

const GeneratedSummary = ({summaryList}) => {
	logger.log(summaryList,'Summary List.....')
    return (
        <div className="theme">
			{/* <h6 className="theme__title">Generated Summary</h6> */}
			<div>
				{summaryList.map((summary,index) => (
					<div className='summary_block' key={summary.id}>
						{/* <p className='participant_count'>Participant {index+1}:</p> */}
						<p className='participant_count'>Theme: {summary.theme}</p>
						<p>{summary.summary}</p>
					</div>
				))}
			</div>
		</div>
    );
}

export default GeneratedSummary;
